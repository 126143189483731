import React, { useCallback, useEffect, useRef, useState } from "react";
import { Container, Row } from "react-grid-system";

import "./assets/css/Modal.css";
import "./assets/css/Mint.css";

//fonts
import "./assets/fonts/ClashDisplay-Semibold.otf";
import "./assets/fonts/MazzardM-Medium.ttf";
import "./assets/fonts/StyreneBLC-Regular.otf";

import centerImage from "./assets/images/question.png";
import matic from "./assets/images/matic.png";

// social
import twitterIcon from "./assets/images/twitter-fill.png";
import telegramIcon from "./assets/images/telegram.png";
import boatIcon from "./assets/images/boat.png";

// CONNECT/DISCONNECT
import Web3Modal from "web3modal";
import {ethers} from "ethers";
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';

const BigNumber = require('bignumber.js');

//0x2105

const providerOptions = {
  injected: {
    display: {
      name: 'Metamask/Phantom',
      description: 'Connect with the provider in your Browser',
      supportedChainIds: ['0x2105'],
      chainId: '0x2105',
    },
    package: null,
  },
  coinbasewallet: {
    package: CoinbaseWalletSDK, 
    options: {
      appName: "Ciao Zhaos Minter",
      supportedChainIds: ['0x2105'],
      chainId: '0x2105',
    }
  },
}

function App() {

    const [state, updateState] = React.useState();

    const [mintValue, setMintValue] = useState(1);

    const [showModal, setShowModal] = useState(true);
    const [activeDiv, setActiveDiv] = useState("div1");
  
    const divOneRef = useRef(null);
    const divTwoRef = useRef(null);
    const wrapperRef = useRef(null);
    const footerRef = useRef(null);

    const web3Modal = new Web3Modal({
      cacheProvider: false, // optional
      providerOptions // required
    });

    const [connectedAddress, setConnectedAddress] = useState(null);
    const [balanceInEth, setBalanceInEth] = useState(null)
    const [web3Provider, setWeb3Provider] = useState(null);
    const [addressSigner, setAddressSigner] = useState(null);

    const [paused, setPaused] = useState(true);
    const [mintState, setMintState] = useState(0);
    const [nowMinting, setNowMinting] = useState(false);
    const [currentSupply, setCurrentSupply] = useState(null);
    const [isWhitelisted, setIsWhitelisted] = useState(null);

    const publicCost = 4400000000000000;

    const threshold = 444;

    const [functionError, setFunctionError] = useState(null);

    const toggleDiv = () => {
        setActiveDiv(activeDiv === "div1" ? "div2" : "div1");
    };

    const resetConnection = () => {
      web3Modal.clearCachedProvider();
      setWeb3Provider(null);
      setAddressSigner(null);
      setIsWhitelisted(null);

      toggleDiv();

      //window.localStorage.clear();
      //window.location.reload();
    }

    async function connectWallet()
    {
      console.log("CONNECTING");

      try 
      {
        const ethersProvider = ethers.getDefaultProvider('https://base.blockpi.network/v1/rpc/public');

        let web3ModalInstance = await web3Modal.connect();
        let web3ModalProvider = new ethers.providers.Web3Provider(web3ModalInstance);

        if (web3ModalProvider.provider.isWalletConnect) 
        {
          setConnectedAddress(web3ModalProvider.provider.accounts[0]);
        } 
        else
        {
          setConnectedAddress(web3ModalProvider.provider.selectedAddress);
        }

        const addBaseMainnet = async () => {
          try {
            await web3ModalProvider.send('wallet_addEthereumChain', [
              {
                chainId: '0x2105',
                chainName: 'Base',
                nativeCurrency: {
                  name: 'Ethereum',
                  symbol: 'ETH',
                  decimals: 18,
                },
                rpcUrls: ['https://base.blockpi.network/v1/rpc/public'], 
                blockExplorerUrls: ['https://basescan.org'],
              },
            ]);
            console.log('Base Mainnet added to Web3 provider');
          } 
          catch (error) 
          {
            console.error('Failed to add Base Mainnet to Web3 provider:', error);
          }
        };

        if (parseInt(web3ModalProvider.provider.chainId) !== 8453) 
        {
          await addBaseMainnet();
        }
        
        let signer = web3ModalProvider.getSigner();

        const contractWithWallet = contract.connect(signer);
        try 
        {
          let currentSupply = await contractWithWallet.getTotalSupply();
          let paused = await contractWithWallet.getPausedState();
          let mintState = 1;
  
          setCurrentSupply(currentSupply);
          setPaused(paused);
          setMintState(mintState);
        }
        catch (error)
        {
          console.log("CCalls Error during connect: " + error);
        }
        
        setWeb3Provider(web3ModalProvider);
        setAddressSigner(signer);

        let walletLink = document.getElementById("walletLink");
        walletLink.href = "https://basescan.org/address/" + web3ModalProvider.provider.selectedAddress;

        let contractLink = document.getElementById("contractLink");
        contractLink.href = "https://basescan.org/address/" + contractAddress;

        ethersProvider.getBalance(web3ModalProvider.provider.selectedAddress).then((balance) => {
          const remainder = balance.mod(1e13);
          setBalanceInEth(ethers.utils.formatEther(balance.sub(remainder)));
          console.log(`balance: ${ethers.utils.formatEther(balance.sub(remainder))} ETH`)
         })
        
        setTimeout(() => {

          const indexWL = whitelistAddresses.findIndex(element => {
            return element.toLowerCase() === web3ModalProvider.provider.selectedAddress.toLowerCase();
          });

          if (indexWL >= 0)
          {
            setIsWhitelisted(true);
          }
          else
            setIsWhitelisted(false);
        }, 0);

      }
      catch(error)
      {
        console.error(error);
      }
    }

    const contractAddress = '0x5Ce17D68dB911cae65c9F6084E89BC9Fd8f43B08';
    
    const ABI = [
      "function setPaused(bool x)",
      "function whitelistMint(bytes32[] x, uint256 y) payable",
      "function publicMint(uint256 x, address y) payable",
      "function rawMint(uint256 x, address y) payable",
      "function getTotalSupply() view returns (uint256)",
      "function getPausedState() view returns (bool)",
    ];

    const contract = new ethers.Contract(contractAddress, ABI, addressSigner);

    const { MerkleTree } = require('merkletreejs');
    const keccak256 = require('keccak256');

    let whitelistAddresses = [
      '0xBa957a05E6F29b4FFF6fA6D9b7e7De5A9e114BcA'
    ];

    const leafNodes = whitelistAddresses.map(addr => keccak256(addr));
    const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true});
    const rootHash = merkleTree.getRoot();

    //console.log("WL: " + rootHash.toString("Hex"));

    async function checkData()
    {
      if (connectedAddress != null)
      {

        let tempPaused = null;
        let tempSupply = null;
        let tempState = null;
        try
        {
          const contractWithWallet = contract.connect(addressSigner);

          if (contractWithWallet != null)
          {
            tempPaused = await contractWithWallet.getPausedState();
            tempSupply = await contractWithWallet.getTotalSupply();
            tempState = 1;
          }
          else
          {
            console.log("Contract With Wallet is Empty");
          }
        }
        catch (error)
        {
          console.log("RPC ERROR: " + error);
        }    

        setPaused(tempPaused);
        setCurrentSupply(tempSupply);
        setMintState(tempState);
      }
    }

    useEffect(() => {
      if (isWhitelisted !== null)
      {
        toggleDiv();
        checkData();
      }

    }, [isWhitelisted]);

    useEffect(() => {
    }, [paused, currentSupply]);

    useEffect(() => {
      const interval = setInterval(() => {
        checkData();
      }, 10000);
    
      return () => clearInterval(interval);
    }, [addressSigner]);

    async function LePublicMint(amount)
    {
      try {
        const ethersProvider = ethers.getDefaultProvider('https://base.blockpi.network/v1/rpc/public');

        setFunctionError(null);
        setNowMinting(true);

        var cost;

        var times = parseInt(amount);
        var pcost = parseInt(publicCost);

        cost = times * pcost;

        console.log(cost);
          
        const contractWithWallet = contract.connect(addressSigner);
        var tx = null;

        tx = await contractWithWallet.publicMint(amount, connectedAddress, { value: cost.toString(), gasLimit: String(250000)});
        await tx.wait();

        checkData();
        setNowMinting(false);

        setFunctionError("Mint Successful!");

        ethersProvider.getBalance(connectedAddress).then((balance) => {
          const remainder = balance.mod(1e13);
          setBalanceInEth(ethers.utils.formatEther(balance.sub(remainder)));
         })

      } catch(err) {
        setFunctionError("Mint Failed: " + err.code);
        console.log(err.code);
        checkData();
        setNowMinting(false);
      }
    }

    useEffect(() => {
      setShowModal(true);
      setTimeout(() => {
        setShowModal(true);
      }, 1000);
    }, []);


    return (
      <div className={`page-wrap`}>
        <Container className='mint-container' fluid>

          <div className='modal-container'>
            <div className='modal' id='modal-window'>
              <div ref={wrapperRef} className='modal-content'>
                <Row>
                  <span className='ca-header'>Ciao Zhao</span>
                </Row>

                <div
                  id='div1'
                  className={activeDiv === "div1" ? "div1 show" : "div1 hide"}
                  ref={divOneRef}
                >
                  <br/>

                  <Row className='margin-block-20'>
                    <button className='connect-button' onClick={connectWallet}>
                      CONNECT YOUR WALLET
                    </button>
                  </Row>
                  <br/>
                  
                  <Row className='margin-block-20'>
                    <span className='metamask-notice'>
                      MOBILE? 
                      <br/>Visit the website on the browser inside your wallet application! Make sure you are connected to BASE!
                    </span>
                  </Row>
                  <br/>
                  <br/>
                </div>
  
                <div
                  id='div2'
                  ref={divTwoRef}
                  className={activeDiv === "div2" ? "div2 show" : "div2 hide"}
                >
                  <Row className="text-box">
                    <a id="contractLink" target="_blank">
                    <span className='text-span'>{"CONTRACT : " + contractAddress.toUpperCase()}</span>
                    </a>
                  </Row>
                  

                  <Row className="margin-bottom-20">
                    <span className='gray-text'> total supply</span>
                  </Row>

                  <Row className="margin-bottom-20">
                    <span className='white-text'> {currentSupply ? currentSupply.toString() + " / 4444" : ""}</span>
                  </Row>                  

                  <br/>

                  <Row className="text-box">
                    <a id="walletLink" target="_blank">
                    <span className='text-span'>{web3Provider ? "WALLET : " + connectedAddress.toUpperCase() : "Loading..."}</span>
                    </a>
                  </Row>

                  <Row className='margin-bottom-40'>
                    <span className='gray-text'> balance </span>
                  </Row>                      

                  <Row className='margin-bottom-20'>
                    <span className='white-text'>
                    {balanceInEth ? balanceInEth : "Loading..."}
                    </span>
                    <img src={matic} alt='ethereumIcon' width='18px' height='18px' />
                  </Row>
                  
                  <br/>
  
                  <p>MAX 10 per wallet!</p>

                  <div className='button-row-content'>

                    <button
                      className='button-decrease-increase'
                      onClick={() => {
                        mintValue !== 1 && setMintValue(mintValue - 1);
                      }}
                    >
                      -
                    </button>

                    <span className='mint-value'>{mintValue}</span>

                    <button
                      className='button-decrease-increase'
                      onClick={() => {
                        mintValue + 1 <= 10 && setMintValue(mintValue + 1)}}
                    >
                      +
                    </button>

                    <button
                      className='button-decrease-increase'
                      onClick={() => {
                        setMintValue(10)}}
                    >
                      Max
                    </button>

                  </div>

                  <Row className='margin-bottom-20'>
                    <span className='gray-text'>
                      Cost
                    </span>
                  </Row>
  
                  <Row className='margin-bottom-20'>
                    <span className='text-eth-value'>{ (parseInt(currentSupply) + parseInt(mintValue)) <= threshold ? 'FREE' : (0.0044 * mintValue).toFixed(4)}</span>
                    <img src={matic} alt='ethereumIcon' width='18px' height='18px'/>
                    <span className='text-span'>+ Gas Fee</span>
                  </Row>

             <br/>     

                  <div className="yellow-text">{functionError}</div>

                  {paused ? <span className= 'info-box'><p className='info-text'>MINTING HASN'T STARTED YET</p></span> : 

                  nowMinting ? <span className= 'info-box'><div className="spinner"></div></span>
                  :
                  <Row className='margin-bottom-20'>
                  <button
                    className='app-button'
                    onClick={() => LePublicMint(mintValue, connectedAddress)}
                  >
                    MINT
                  </button>
                </Row>
                }
                </div>
              </div>
  
              <div ref={footerRef} className='modal-footer'>
                <div className='modal-footer-content'>
                  <a href="https://twitter.com/CiaoZhaoNFT" target="_blank" rel="noreferrer" className="link">
                    <img src={twitterIcon} alt='twitterIcon' />
                  </a>
                </div>
                <p className='yellow-text'>99% of errors can be solved by reloading the page!</p>
                <p className='footer-text'>© 2024 Ciao Zhao NFT. All Rights Reserved.</p>

              </div>
            </div>
          </div>
        </Container>
      </div>
  );
}

export default App;
